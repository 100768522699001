@font-face {
  font-family: 'script';
  src: url('./fonts/advertisingScript/advertisingScript.ttf');
}
.script {
  font-family: 'script';
}
.full-page {
  min-height: 100vh;
}
.bold {
  font-weight: bold;
}
.box {
  border-radius: 10px;
  box-shadow: 0 0 10px var(--bs-gray-300);
}
.login {
  width: 475px;
  background-color: #ffffff;
}
.error {
  font-size: 12px;
  opacity: 0.8;
  color: var(--bs-danger);
}
.pointer {
  cursor: pointer;
}
.link {
  color: var(--bs-primary);
  cursor: pointer;
}
.bg-white {
  background-color: #ffffff;
}
.logo {
  color: var(--bs-dark);
  font-family: 'Script';
  text-decoration: none;
  font-size: 1.75rem;
  padding-bottom: 0;
}
.app-footer {
  background-color: var(--bs-dark);
  color: var(--bs-light);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-footer a {
  text-decoration: none;
  font-weight: bold;
}
.dashboard-card {
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 0 10px var(--bs-gray-500);
  padding: 16px;
  cursor: pointer;
  transition: 0.3s;
  background-color: #ffffff;
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    height: 96px;
  }
  p {
    font-size: 22px;
    font-weight: bold;
  }
}
.dashboard-card:hover {
  transform: translate(0, -10px);
}
.disabled-img {
  filter: saturate(0);
}
.layout-main {
  min-height: 100vh;
  padding-bottom: 60px;
  background-color: var(--bs-light);
  position: relative;
}
.layout-footer {
  width: 100%;
  bottom: 0;
  height: 60px;
}
.page-title {
  font-weight: bold;
  text-decoration: underline;
}
.details-info {
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--bs-gray-200);
  display: flex;
  font-size: 18px;
  .label {
    font-weight: bold;
    opacity: 0.7;
    width: 35%;
  }
  .value {
    width: 65%;
    font-weight: bold;
  }
}
.profile-img-container {
  .img-main {
    width: 100%;
  }
  .img-thumb {
    width: 128px;
    border: 2px solid transparent;
  }
  .img-thumb.active {
    border: 2px solid var(--bs-primary);
  }
}
.add-profile-image {
  position: relative;
  max-height: 320px;
  overflow: hidden;
  img {
    border-radius: 10px;
    border: 5px solid var(--bs-gray-200);
    width: 100%;
  }
  .delete-image-btn {
    color: var(--bs-light);
    background-color: var(--bs-danger);
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    cursor: pointer;
  }
}
.add-image-btn {
  width: 100px;
  height: 100px;
}
.hide-arrrow {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
.react-select__input-container {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.react-select__menu {
  background-color: white;
  z-index: 99999 !important;
}
.testimony {
  border-radius: 10px;
  box-shadow: 5px 5px 10px var(--bs-gray-200);
  overflow: hidden;
  .image {
    position: relative;
    img {
      width: 100%;
    }
    .btn-img-update {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
  .body {
    padding: 10px;
    h4,
    p {
      margin-bottom: 0;
    }
  }
}
.gallery-item {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  img {
    border-radius: 10px;
    box-shadow: 5px 5px 10px var(--bs-gray-300);
    transition: 0.3s;
  }
  img:hover {
    box-shadow: 0px 0px 20px var(--bs-gray-300);
    transform: translate(0, -5px) scale(1.05);
  }
  .btn-img-update {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
